import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getCuadrantes(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getCuadrantes,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getCuadrante(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getCuadrante,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getComunas(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getComunas,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getRegiones(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getRegiones,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getRegionesByPais(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getRegionesByPais,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getPaises(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getPaises,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function deleteCuadrante(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteCuadrante,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function updateCuadrante(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateCuadrante,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}