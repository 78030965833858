<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Publicidad"
      size="lg"
      color="primary"
      :show.sync="showModal"
    >
      <CCard style="max-height: 70vh;">
        <CCardHeader>
        </CCardHeader>
        <CCardBody>
          <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="12" class="c-bg-success c-py-3">
              <CForm style="overflow-y: visible;">
                <p class="mb-0 font-weight-bolder">Tipo de publicidad</p>
               <!--  <select class="mb-3" v-model="newPublicidad.type">
                  <option :value="0">Imagen</option>
                  <option :value="1">Video</option>
                </select> -->
                <v-select
                  class="my-2" placeholder="Selecciona tipo de publicidad"
                  :options="[
                    {id: 0, name: 'Imagen'},
                    {id: 1, name: 'Video'}
                  ]"
                  :reduce="data => data.id"
                  label="name" v-model="newPublicidad.type"></v-select>
                <CInput
                  placeholder="Título"
                  v-model='newPublicidad.title'
                >
                  <template #prepend-content><CIcon name="align-left"/></template>
                </CInput>
                <CInput
                  placeholder="Descripción"
                  v-model='newPublicidad.message'
                >
                  <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                </CInput>
                <CInput
                  placeholder="URL Video o Imagen"
                  v-model='newPublicidad.url'
                >
                  <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                </CInput>
                <CInput
                  placeholder="URL Imagen de Fondo (opcional)"
                  v-model='newPublicidad.backImg'
                >
                  <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                </CInput>
                <CInput
                  placeholder="Link de destino"
                  v-model='newPublicidad.link'
                >
                  <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                </CInput>
                <CInput
                  type="date"
                  placeholder="Fecha inicio"
                  v-model='newPublicidad.start'
                >
                  <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                </CInput>
                <CInput
                  type="date"
                  placeholder="Fecha fin"
                  v-model='newPublicidad.end'
                >
                  <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                </CInput>
              </CForm>
            </CCol>
          </CRow>
          <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="12" class="c-bg-success c-py-3">
              <p class="mb-0 font-weight-bolder">Target</p>
              <p class="mb-0">Si quieres enviar esta publicidad a todos los usuarios de la aplicación no selecciones nada.</p>
            </CCol>
            <CCol lg="3" class="c-bg-success c-py-3">
              Género
              <v-select
                class="my-2" placeholder="Selecciona género"
                :options="[
                  {id: 'mujeres', name: 'Mujeres'},
                  {id: 'hombres', name: 'Hombres'}
                ]"
                :reduce="data => data.id"
                label="name" v-model="target.genero"></v-select>
            </CCol>
            <CCol lg="3" class="c-bg-success c-py-3">
              Rango Etario
              <v-select
                class="my-2" placeholder="Selecciona rango edades"
                :options="[
                  {id: 'age-f', name: '65 +'},
                  {id: 'age-e', name: '60 - 65'},
                  {id: 'age-d', name: '50 - 59'},
                  {id: 'age-c', name: '40 - 49'},
                  {id: 'age-b', name: '30 - 39'},
                  {id: 'age-a', name: '18 - 29'},
                ]"
                :reduce="data => data.id"
                label="name" v-model="target.edad"></v-select>
            </CCol>
            <CCol lg="3" class="c-bg-success c-py-3">
              Comunas
              <v-select
                class="my-2" placeholder="Selecciona comuna"
                :options="comunas"
                :reduce="data => data.comuna"
                searchable
                label="comuna" v-model="target.comuna"></v-select>
            </CCol>
            <CCol lg="3" class="c-bg-success c-py-3">
              Regiones
              <v-select
                class="my-2" placeholder="Selecciona Región"
                :options="regiones"
                :reduce="data => data.abreviatura"
                searchable
                label="region" v-model="target.region"></v-select>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    	<template #footer>
	      <CButton
	        color="primary"
	        size="sm"
	        class="m-1"
	        @click="closeModal"
	      >
	        Cancelar
	      </CButton>
	      <CButton
	        color="success"
	        class="m-1"
	        @click="savePublicidad()"
	      >
	        Enviar Publicidad
	      </CButton>
    	</template>
    </CModal>
  </div>
</template>

<script>
import { sendPublicidad } from '../../api/publicidad'
import { getComunas, getRegiones } from '../../api/cuadrantes'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'NewPublicidad',
  props: ['showModal', 'closeModal', 'publicidad'],
  components: {'v-select': vSelect},
  data: () => {
    return {
    	newPublicidad: {
        type: 0,
        url: '',
        backImg: '',
        link: '',
        title: '',
        message: '',
        start: '',
        end: '',
        topic: ''
    	},
      target: {
        genero: '',
        edad: '',
        comuna: '',
        region: ''
      },
      comunas: [],
      regiones: [],
      toasts: [],
    }
  },
  watch:{
    publicidad: function(){
      if(this.publicidad){
        this.newPublicidad = {
          id: this.publicidad.id,
          type: this.publicidad.data.type,
          url: this.publicidad.data.url,
          backImg: this.publicidad.data.backImg,
          link: this.publicidad.data.link,
          title: this.publicidad.data.title,
          message: this.publicidad.data.message,
          start: this.publicidad.start,
          end: this.publicidad.end,
        }
        this.target = this.publicidad.target
      }
    }
  },
  mounted(){
    this.getComunas()
    this.getRegiones()
  },
  methods: {
    getComunas(){
      const self = this
      getComunas()
        .then(function(resp){
          self.comunas = resp.comunas
          console.log(resp)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getRegiones(){
      const self = this
      getRegiones()
        .then(function(resp){
          self.regiones = resp.regiones
          console.log(resp)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    setTopics(){
      let topic = ''
      if(this.target.genero && this.target.genero !== ''){
        topic += this.target.genero.toLowerCase().replace(new RegExp(' ', 'g'), '')
      }
      if(this.target.edad && this.target.edad !== ''){
        topic += this.target.edad.toLowerCase().replace(new RegExp(' ', 'g'), '')
      }
      if(this.target.comuna && this.target.comuna !== ''){
        topic += this.target.comuna.toLowerCase().replace(new RegExp(' ', 'g'), '')
      }
      if(this.target.region && this.target.region !== ''){
        topic += 'reg-' +this.target.region.toLowerCase().replace(new RegExp(' ', 'g'), '')
      }
      return topic
    },
    savePublicidad(){
      const self = this
      this.newPublicidad.topic = this.setTopics()
      this.newPublicidad.target = this.target
      sendPublicidad(this.newPublicidad)
        .then(function(resp){
          console.log("Top: ", resp)
          self.closeModal()
        })
        .catch(function(error){
          console.log("TOp err: ", error)
        })
    }
  }
}
</script>