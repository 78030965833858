<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Publicidad
            <CButton
              v-if="checkPermissions(['edit_publicidad'])"
              color="primary"
              size="sm"
              class="m-1"
              @click="crearPublicidad(null, $event)"
            >
              Agregar
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="pagination.perPage"
              index-column
              clickable-rows
              @row-clicked="goToPublicidad"
            >
              <template #actions="data">
                <CButton
                  v-if="checkPermissions(['edit_publicidad'])"
                  color="light"
                  size="sm"
                  class="m-1"
                  @click="resendPublicidad(data.item, $event)"
                >
                  <font-awesome-icon icon="share" />
                </CButton>
                <td v-else>
                  --
                </td>
              </template>
            </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <Pagination :data="pagination" :callback="paginate"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
    <NewPublicidad :publicidad="editPublicidad" :showModal="showModal" :closeModal="() => closeModal()"/>
  </CRow>
</template>

<script>
import Pagination from '../../helpers/Pagination'
import { publicidadList, sendPublicidad } from '../../api/publicidad'
import NewPublicidad from './NewPublicidad'
import checkPermissions from '../../helpers/userPermissions'

export default {
  name: 'Publicidad',
  components: {Pagination, NewPublicidad},
  data: () => {
    return {
      items: [],
      dataLoading: true,
      fields: [
        { key: 'id', label: '#' },
        { key: 'title', label: 'Título' },
        { key: 'description', label: 'Descripción' },
        { key: 'start', label: 'Inicio' },
        { key: 'end', label: 'Fin' },
        { key: 'actions', label: 'Acciones' }
      ],
      pagination: {
        page: 1,
        perPage: 10,
        total: null
      },
      formPublicidad: null,
      showModal: false,
      loading: true,
      comunas: null,
      editPublicidad: null
    }
  },
  mounted(){
    this.getPublicidad()
  },
  methods: {
    checkPermissions,
    paginate(page, perPage){
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total
      }
      this.getPublicidad()
    },
    goToPublicidad(publicidad){
      this.$router.push({path: 'publicidad/' + publicidad.id})
    },
    closeModal(){
      this.showModal = false
      this.getPublicidad()
    },
    crearPublicidad(publicidad, e){
      e.stopPropagation()
      this.showModal = true
    },
    getPublicidad(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page
      }
      const self = this
      this.loading = true
      publicidadList(data)
        .then(function(resp){
          self.items = resp.data
          self.pagination = {
            page: self.pagination.page,
            perPage: self.pagination.perPage,
            total: resp.total
          }
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    resendPublicidad(publicidad, e){
      e.stopPropagation()
      const self = this
      console.log(publicidad)
      this.editPublicidad = publicidad
      this.showModal = true
     //  const data = {
     //  	type: publicidad.data.type,
     //    url: publicidad.data.url,
     //    backImg: publicidad.data.backImg,
     //    link: publicidad.data.link,
     //    title: publicidad.data.title,
     //    message: publicidad.data.message,
     //    start: publicidad.start,
     //    end: publicidad.end
    	// }
     //  sendPublicidad(data)
     //    .then(function(resp){
     //      console.log("Top: ", resp)
     //  		self.getPublicidad()
     //    })
     //    .catch(function(error){
     //      console.log("TOp err: ", error)
     //    })
    },
    getBadge (status) {
      return status === 0 ? 'primary'
        : status === 1 ? 'danger'
        : status === 2 ? 'warning' : null
    },
    getEstado (status) {
      return status === 0 ? 'Activa'
        : status === 1 ? 'Cerrada'
        : status === 2 ? 'Acción Realizada' : null
    }
  }
}
</script>